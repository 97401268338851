<template>
  <section class="expendParts" id="miningEquipment">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>
            <v-img src="../../assets/Icon/mining2-alt.svg" width="53" height="50"></v-img>
            <span class="bold">NEW EQUIPMENT </span>& COMPONENTS
          </h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <h3 class="center">We select, deliver and implement optimal technological solutions using reliable high-performance equipment from Europe. Working directly with many manufacturers, we can find the required equipment, guarantee competitiveness and bring maximum benefit to our client. </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="page-list-text">
          <div>
            <h4 class="left">Range of equipment:</h4>
            <ul>
              <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/new equipment 2.jpg" height="525"></v-img>
        </v-col>
        <v-col cols="12" v-if="brands.length > 0">
          <h3>Brands:</h3>
          <v-chip class="v-size--default orange white--text" v-for="item in brands" :key="item">{{item}}</v-chip>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'miningEquipment',
  data () {
    return {
      brands: [],
      list: ['Conveyors & Belts', 'Materials Storage Equipment', 'Crushers & Screens', 'Compressors', 'Pumps', 'Electrical Motors', 'Fittings', 'Instrumentation & Automation']
    }
  }
}
</script>

<style scoped>

</style>
