<template>
  <section class="expendParts" id="miningSpare">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>
            <v-img src="../../assets/Icon/mining1-alt.svg" width="53" height="50"></v-img>
            <span class="bold">SPARE </span>& WEAR PARTS
          </h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <h3 class="center">In addition VERTEX has a strong focus on after-sales customer service and provides full range of spares and consumables for the mining plants. In addition VERTEX has a strong focus on after-sales customer service and provides full range of spares and consumables for the mining plants.</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/mining2.jpg" height="525"></v-img>
        </v-col>
        <v-col cols="12" sm="6" class="page-list-text">
          <div>
            <h4 class="left">Range of parts and consumables:</h4>
            <ul>
              <li v-for="itemList in list" :key="itemList">{{itemList}}</li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" v-if="brands.length > 0">
          <h3>Brands:</h3>
          <v-chip class="v-size--default orange white--text" v-for="item in brands" :key="item">{{item}}</v-chip>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'minigSpare',
  data () {
    return {
      brands: [],
      list: ['Lining for Ball Mills and Screens', 'Lining for Crushers', 'Conveyor Belts', 'Bearings', 'Gearboxes', 'Hydraulic Cylinders', 'Electrical parts', 'Grinding Media']
    }
  }
}
</script>

<style scoped>

</style>
