<template>
  <section class="mining">
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <mining-equipment></mining-equipment>
    <minig-spare class="gray"></minig-spare>
    <mining-modernization></mining-modernization>
    </section>
  </template>

<script>
import GeneralSecond from '@/components/GeneralSecond'
import MiningEquipment from '@/components/mining/miningEquipment'
import MinigSpare from '@/components/mining/minigSpare'
import MiningModernization from '@/components/mining/miningModernization'
export default {
  name: 'Mining',
  components: {
    MiningModernization,
    MinigSpare,
    MiningEquipment,
    GeneralSecond
  },
  data () {
    return {
      page: {
        pageName: 'Mining',
        description: 'VERTEX supplies technological solutions, equipment and materials including expert services for minerals processing. Our company’s target is to provide the best solution in terms of technology, lifetime and CAPEX & OPEX to our clients.'
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'Mining',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style>

</style>
