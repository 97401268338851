<template>
  <section class="expendParts" id="miningModernization">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>
            <v-img src="../../assets/Icon/mining-4-alt.svg" width="53" height="50"></v-img>
            <span class="bold">MODERNIZATION</span> & repair works
          </h2>
          <div class="orange-line center">
            <div class="orange"></div>
            <div class="line"></div>
          </div>
          <h3>The range of services of VERTEX includes not only spare & wear parts but also modernization and repair
            works in
            order to improve the efficiency and lifetime of production lines and equipment. VERTEX supports its clients
            in
            different ways – anytime, fast and everywhere.</h3>
        </v-col>

        <v-col cols="12" sm="6" class="page-list-text">
          <div>
            <h4>These are the services we offer:</h4>
            <ul>
              <li v-for="itemList in list" :key="itemList">{{ itemList }}</li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img src="../../assets/Modernization.jpg" height="525"></v-img>
        </v-col>
        <v-col cols="12" v-if="brands.length > 0">
          <h3>Brands:</h3>
          <v-chip class="v-size--default orange white--text" v-for="item in brands" :key="item">{{ item }}</v-chip>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'miningModernization',
  data () {
    return {
      brands: [],
      list: ['Feasibility studies for your project', 'Support with assembly and commissioning from our specialists', 'Technical training of your employees for operation and maintenance', 'After sales service including consultation on operation or maintenance issues', 'Modernization of existing plants, e.g. by retrofitting to increase capacities or lifetime', 'Repairs of old & damaged equipment on and also off plant site']
    }
  }
}
</script>

<style>

</style>
